import React, { useState, useEffect, useRef } from "react"
import styled, { createGlobalStyle } from "styled-components"
import { Link } from "gatsby"
import "./nav2.css"

import app_store from "../images/app_store.png"
import black_logo_text from "../images/black_logo_text.png"
import white_logo_text from "../images/white_logo_text.png"
import grey_logo from "../images/logos/cloud_icon_gray.png"


import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import Toolbar from "@mui/material/Toolbar"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"
import Menu from "../../node_modules/@mui/material/Menu"
import MenuIcon from "@mui/icons-material/Menu"
import Container from "@mui/material/Container"
import Avatar from "@mui/material/Avatar"
import Button from "@mui/material/Button"
import Tooltip from "@mui/material/Tooltip"
import MenuItem from "@mui/material/MenuItem"


// const pages = ["Own", "Alpha"]

var myObject = { 'Own': "/own-a-vacation-home/", 
                 'Alpha': "/alpha/",
                 'Meet': "/setup-a-meeting/",
                 'Resources': "/resources/",
                 };

// const links = ["/own-a-vacation-home/", "/alpha/"]
// const settings = ["Support", "Android", "Terms Of Service", "Privacy"]

const objSettings = {
  "Cloud Castles Support":"/support/", 
  // "Android":"/android/", 
  "Terms Of Service":"/terms-of-service/", 
  "Privacy Policy":"/privacy-policy"

}

const BasicAppBar = () => {
  const [settingsPressed, setSettingsPressed] = React.useState("")

  const [anchorElNav, setAnchorElNav] = React.useState(null)
  const [anchorElUser, setAnchorElUser] = React.useState(null)

  const [openDrawer, toggleDrawer] = useState(false)
  const drawerRef = useRef(null)
  const [scroll, setScroll] = useState(0)
  const [buttonColor, setButtonColor] = React.useState("")

  const dropdownRef = useRef(null)
  const dropdownRef2 = useRef(null)
  const [isActive, setIsActive] = useState(false)
  const [isActive2, setIsActive2] = useState(false)
  const onClick = () => setIsActive(!isActive)
  const onClick2 = () => setIsActive2(!isActive2)

  const handleOpenNavMenu = event => {
    setAnchorElNav(event.currentTarget)
  }
  const handleOpenUserMenu = event => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const handleCloseUserMenu = () => {
    console.log("")
    setAnchorElUser(null)
  }

  const pressedSettings = event => {

    console.log(event.currentTarget)
  }

  useEffect(() => {
    /* Close the drawer when the user clicks outside of it */
    const closeDrawer = event => {
      if (drawerRef.current && drawerRef.current.contains(event.target)) {
        return
      }

      toggleDrawer(false)
    }
    setButtonColor('white')

    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 20)
       if (window.scrollY > 20) {
        setButtonColor('black')
      } else {
        setButtonColor('white')
      }
    })

    document.addEventListener("mousedown", closeDrawer)
    return () => document.removeEventListener("mousedown", closeDrawer)
  }, [])

  return (
    <Styles.Wrapper>
      <Navbar.Wrapper scroll={scroll}>
        
          <Container maxWidth="xl">
            <Toolbar disableGutters>
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ mr: 2, display: { xs: "none", md: "flex" } }}
              >
                <a href="/">
                  <Navbar.Logo
                    src={black_logo_text}
                    alt="Logo"
                  />
                </a>
              </Typography>

              <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                <IconButton
                  size="large"
                  aria-label="settings"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  color="inherit"
                  onClick={handleOpenNavMenu}
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: "block", md: "none" },
                  }}
                >
                </Menu>
              </Box>
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                <a href="/">
                  <Navbar.Logo
                    src={black_logo_text}
                    alt="Logo"
                  />
                </a>
              </Typography>
              <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>

              </Box>

              <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar
                      sx={{  borderRadius: 0 }}
                      alt="Settings"
                      src={grey_logo}
                    />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >         
                {Object.entries(objSettings).map(([key, value]) =>
                    <MenuItem key={key} onClick={handleCloseUserMenu} href={value}>
                    <a style={{"text-decoration": "none"}} href={value}>
                      <Typography textAlign="center" onClick={pressedSettings}>{key}</Typography>
                      </a>
                    </MenuItem>
                  )}      
                </Menu>
              </Box>
            </Toolbar>
          </Container>
        
      </Navbar.Wrapper>
    </Styles.Wrapper>
  )
}
export default BasicAppBar

const Styles = {
  Wrapper: styled.main`
    position: fixed;
    width: 100%;
    height: 100px;
    padding: 0px 0;
    z-index: 100;
    transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  `,
}

const Navbar = {
  Wrapper: styled.nav`
    max-height: 60px;
    background: ${({ scroll }) => (scroll ? "white" : "transparent")};

    // 40em == 640px
    @media only screen and (max-width: 35em) {
      max-height: 80px;
    }
  `,

  Logo: styled.img`
    max-width: 200px;
    margin-bottom:5px;
    cursor: pointer;
    /*padding: 0px 0px 0px 30px;
    
    margin-right: 30px;
    position: absolute;*/
    @media only screen and (max-width: 40em) {
      max-width: 100px;
    }
  `,
  Items: styled.ul`
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: flex-start;
    list-style: none;
    padding: 0px 0px 0px 300px;
    margin-top: 15px;
    margin-right: 30px;
    @media only screen and (max-width: 40em) {
      position: fixed;
      right: -30px;
      top: 0;

      height: 100%;

      flex-direction: column;

      background-color: white;
      padding: 1rem 2rem;

      transition: 0.2s ease-out;

      transform: ${({ openDrawer }) =>
        openDrawer ? `translateX(0)` : `translateX(100%)`};
    }
  `,

  Link: styled.a`
    cursor: pointer;
    padding: 8px 30px;
    margin: 8px 50px;
    font-size: 20px;
    border: none;
    font-weight: 350;
    border-radius: 10px;
    transition: 1s;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

    @media only screen and (max-width: 40em) {
      padding: 1rem 0;
    }
    &:hover {
      /*color: grey;*/
      /*shadow*/

      /*moves up*/
      /*transform:translateY(-3px);*/
    }
  `,

  Item: styled.a`
    color: black;
    cursor: pointer;
    padding: 8px 30px;
    margin: 8px 50px;
    font-size: 20px;
    border: none;
    font-weight: 700;
    border-radius: 10px;
    transition: 1s;
    background: ${({ scroll }) => (scroll ? "black" : "white")};

    @media only screen and (max-width: 40em) {
      padding: 1rem 0;
    }
    &:hover {
      color: grey;
      /*shadow*/
      /*box-shadow: 0 10px 20px rgba(0,0,0,0.25);*/
      /*moves up*/
      transform: translateY(-3px);
    }
  `,
}

const HamburgerButton = {
  Wrapper: styled.button`
    width: 3rem;
    position: relative;
    font-size: 12px;
    background: white;
    display: none;

    @media only screen and (max-width: 40em) {
      display: block;
      height: 10rem;
    }

    /* Remove default button styles */
    border: none;
    background: transparent;
    outline: none;

    cursor: pointer;

    &:after {
      content: "";
      display: block;
      position: absolute;
      height: 150%;
      width: 150%;
      top: -25%;
      left: -25%;
    }
  `,
  Lines: styled.div`
    top: 50%;
    margin-top: -0.125em;
    right: 0;

    &,
    &:after,
    &:before {
      /* Create lines */
      height: 2px;
      pointer-events: none;
      display: block;
      content: "";
      width: 100%;
      background-color: black;
      position: absolute;
    }

    &:after {
      /* Move bottom line below center line */
      top: -0.8rem;
    }

    &:before {
      /* Move top line on top of center line */
      top: 0.8rem;
    }
  `,
}

const CSSReset = createGlobalStyle`
  *,
  *::before, 
  *::after {
    margin: 0; 
    padding: 0;
    box-sizing: inherit;
  }

  html {
    font-size: 62.5%; /*1rem = 10px*/
    box-sizing: border-box;      
  }  

  body {
    font-size: 1.4rem;
    font-family: sans-serif;
  }
`
